import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { AsideMenu } from '@/components/aside/AsideMenu/AsideMenu';
import { AsideMenu as AsideMenuV2 } from '@/components/aside/AsideMenuV2/AsideMenu';
import { HeaderTopBar } from '@/components/HeaderTopBar/HeaderTopBar';
import MetaDataTags from '@/components/metaSchema';
import { useFeatureFlag } from '@/helpers/hooks/useFeatureFlag';
import useUserRewardsService from '@/helpers/hooks/useUserRewardsService';
import { useUserStatsService } from '@/helpers/hooks/useUserStatsService';
import useAnalyticsStore from '@/stores/analyticsStore';
import useMenuStore from '@/stores/menuStore';
import useNotificationBannerStore from '@/stores/notificationBannerStore';
import useUserStore from '@/stores/userStore';
import layoutComponents from './imports';
import { ILayoutProps, TLayout, TScreenWidth } from './types';
// TODO: (Maxim) add global styles export to moralis-ui
import 'moralis-ui/dist/style.css';
import styles from './styles.module.scss';
import typography from '@/styles/scss/typography.module.scss';

const SearchV2 = dynamic(() => import('@/components/SearchV2/SearchV2').then((mod) => mod.SearchV2), { ssr: false });
const Footer = dynamic(() => import('@/components/footer'), { ssr: false });
const StoreDetailDrawer = dynamic(
  () => import('@/components/common/StoreDetailDrawer').then((mod) => mod.StoreDetailDrawer),
  { ssr: false },
);

const {
  AuthModalTemplate,
  ErrorBoundary,
  ErrorFeedback,
  HeaderMenu,
  BottomMenu,
  NotificationBanner,
  SmartNotificationBanner,
} = layoutComponents;

const mainId = 'scrollableMain';
export const topPositionSpanId = 'mainTopPosition';
export const screenWidth = '964px';

const useTrialBanner = () => {
  const dismissTrialBanner = useNotificationBannerStore((state) => {
    return state.dismissTrialBanner;
  });
  const isTrialBannerVisible = useNotificationBannerStore((state) => {
    return state.isTrialBannerVisible;
  });
  const tracker = useAnalyticsStore((state) => {
    return state.tracker;
  });

  const [trialBannerVisible, setTrialBannerVisible] = useState(false);

  const dismissBanner = () => {
    tracker?.trackButtonClick('General Banner Dismissed');
    dismissTrialBanner();
  };

  useEffect(() => {
    setTrialBannerVisible(isTrialBannerVisible);
  }, [isTrialBannerVisible]);

  const isPaidUser = useUserStore((state) => Boolean(state.stage && state.stage.level && state.stage?.level > 1));

  return {
    isVisible: !(!trialBannerVisible || isPaidUser),
    dismissBanner,
    trackLinkClick: () => {
      tracker?.trackButtonClick('General Banner Clicked');
    },
  };
};

export const Layout = ({
  children,
  customClassName,
  hasFooter = true,
  hasHeaderMenu = true,
  hasMainMenu = true,
  hasMobileMenu = true,
  hasMobileHeaderMenu = true,
  layout,
  metaDataTags = {},
  noPadding = false,
  pageNotification,
  screenWidth = 'normal',
  standardPageTracking = true,
  sidebar,
  title,
}: ILayoutProps) => {
  const { getUserStats } = useUserStatsService();
  const { getUserRewards } = useUserRewardsService();
  const trialBanner = useTrialBanner();
  const hasNewSidebar = useFeatureFlag('sidebar');

  const isCollapsed = useMenuStore((state) => {
    return state.isCollapsed;
  });

  const tracker = useAnalyticsStore((state) => {
    return state.tracker;
  });
  useEffect(() => {
    const pageTracking = () => {
      tracker?.trackPageView();
    };
    if (standardPageTracking && origin !== '') {
      pageTracking();
    }
  }, [tracker]);

  useEffect(() => {
    getUserStats();
    getUserRewards();
  }, [children]);

  const getMaxWidth = (layout?: TLayout, screenWidth?: TScreenWidth) => {
    if (layout === 'tokenExp') return styles.maxWidthTokenExp;
    if (layout === 'sidebar') return styles.maxWidthFull;
    if (screenWidth === 'wide') return styles.maxWidthWide;
    if (screenWidth === 'full') return styles.maxWidthFull;
    return styles.maxWidthNormal;
  };

  const getPadding = (layout?: TLayout, screenWidth?: TScreenWidth) => {
    if (layout === 'tokenExp') return styles.paddingTokenExp;
    if (layout === 'sidebar') return styles.paddingSidebar;
    if (screenWidth === 'wide') return styles.paddingWide;
    if (screenWidth === 'full') return styles.paddingFull;
    return styles.paddingNormal;
  };

  const getWidth = (layout?: TLayout, screenWidth?: TScreenWidth) => {
    if (layout === 'tokenExp') return styles.widthTokenExp;
    if (layout === 'sidebar') return styles.widthSidebar;
    if (screenWidth === 'wide') return styles.widthWide;
    if (screenWidth === 'full') return styles.widthFull;
    return styles.WidthNormal;
  };

  // TODO make token-single-twitter-iframe handle customClassName like the others as scss
  return (
    <div
      className={clsx(styles.layout, customClassName, noPadding === true ? styles.noPadding : null, {
        [styles['token-single-twitter-iframe']]: customClassName === 'token-single-twitter-iframe',
      })}
    >
      <MetaDataTags {...metaDataTags} />

      {hasMainMenu &&
        (hasNewSidebar ? <AsideMenuV2 isCollapsed={isCollapsed} /> : <AsideMenu isCollapsed={isCollapsed} />)}

      {hasMobileHeaderMenu && <HeaderMenu />}

      <main
        className={clsx(styles.main, {
          [styles.isCollapsed]: isCollapsed,
          [styles.noMainMenu]: !hasMainMenu,
          [styles.noMainMenu]: !hasMainMenu,
          [styles.noMobileHeaderMenu]: !hasMobileHeaderMenu,
        })}
        id={mainId}
      >
        {hasHeaderMenu && !hasNewSidebar && <HeaderTopBar />}
        <span id={topPositionSpanId} />
        {pageNotification ? (
          <>
            {trialBanner.isVisible && (
              <NotificationBanner
                {...pageNotification}
                onDismiss={trialBanner.dismissBanner}
                onLinkClicked={trialBanner.trackLinkClick}
              />
            )}
          </>
        ) : (
          <SmartNotificationBanner />
        )}
        <div
          className={`${styles.inner}
          ${getMaxWidth(layout, screenWidth)}
          ${getPadding(layout, screenWidth)}
          ${getWidth(layout, screenWidth)}`}
        >
          {title && (
            <span className={styles.layoutH1}>
              <h1 className={typography.display1}>{title}</h1>
            </span>
          )}

          <ErrorBoundary fallbackUI={<ErrorFeedback />}>
            <div className={styles.container}>
              {sidebar?.children && (
                <div
                  className={clsx(styles.sidebar, styles[sidebar?.width || 'md'], {
                    [styles.closed]: layout === 'sidebar' && sidebar?.isOpen === false,
                    [styles.mobileFull]: (layout === 'sidebar' && sidebar?.fullScreenOnMobile) || false,
                    [styles.noTopBar]: !hasHeaderMenu || hasNewSidebar,
                  })}
                >
                  {sidebar.children}
                </div>
              )}
              <div
                className={clsx(styles.inner, {
                  [styles.innerWithSidebar]: layout === 'sidebar' && sidebar?.children,
                })}
              >
                {children}
              </div>
            </div>
            {sidebar?.footer}
          </ErrorBoundary>
        </div>
        {hasFooter && <Footer />}
        {hasMobileMenu && <BottomMenu />}
      </main>
      <AuthModalTemplate />
      <StoreDetailDrawer />

      <SearchV2 />
    </div>
  );
};

import dynamic from 'next/dynamic';
import Link from 'next/link';
import clsx from 'clsx';
import { Nav } from 'moralis-ui';
import { AsideMenuLogoUI } from './AsideMenuLogoUI';
import { TNavItem } from './useAsideMenuData';
import styles from './AsideMenuUI.module.scss';

const AsideMenuFooterUI = dynamic<{ isCollapsed: boolean }>(
  () => import('@/components/aside/AsideMenu/AsideMenuFooterUI').then((mod) => mod.AsideMenuFooterUI),
  {
    ssr: false,
  },
);

export const AsideMenuUI = ({ isCollapsed, menuItems }: { isCollapsed: boolean; menuItems: TNavItem[] }) => {
  return (
    <aside
      className={clsx(styles.mainMenu, { [styles.collapsed]: isCollapsed })}
      id={isCollapsed ? 'sidebarCollapsed' : 'sidebarExpanded'}
    >
      <div className={styles.logo}>
        <AsideMenuLogoUI collapsed={isCollapsed} />
      </div>

      <div className={styles.inner}>
        <div className={styles.innerMainNav}>
          <Nav
            isCollapsed={isCollapsed}
            variant="filled"
            className={clsx(styles.navMenu, { [styles.collapsed]: isCollapsed })}
          >
            {menuItems.map((item) => (
              <Link href={item.href} key={item.id} passHref>
                <Nav.Item icon={item.icon} isActive={item.isCurrent} onClick={item.onClick}>
                  {item.label}
                </Nav.Item>
              </Link>
            ))}
          </Nav>
        </div>

        <AsideMenuFooterUI isCollapsed={isCollapsed} />
      </div>
    </aside>
  );
};

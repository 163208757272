import { useRouter } from 'next/router';
import { ButtonPrimary, Empty } from 'moralis-ui';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EPortfolioList, UserWallet } from '../manageWallets/libs/types';
import { manageWalletStore } from '../manageWallets/stores/manageWalletStores';
import { canAddNewWallet } from '../manageWallets/utils/canAddNewWallet';
import { aggregateAddresses } from '../manageWallets/utils/list';
import { portfolioURL } from '../portfolio/lib/portfolioURL';
import { useAccountSelectorStore } from './utils/accountSelectorStore';
import { filterPortfolioDataByList } from './utils/filterPortfolioDataByList';
import { Aggregate } from './Aggregate';
import { Wallet } from './Wallet';
import styles from './WalletList.module.scss';

export const WalletList = ({ list, tabId }: { list: Array<UserWallet>; tabId: EPortfolioList }) => {
  const { push } = useRouter();
  const { activeSelection, setActiveSelection } = useAccountSelectorStore();
  const setIsAddWalletModalOpen = manageWalletStore((state) => state.setIsAddWalletModalOpen);
  const filteredWallets = filterPortfolioDataByList(list, tabId);

  const hasAggregate = list.map((data) => data.apiData.settings?.includeInAggregateView).filter(Boolean).length > 1;
  return (
    <div className={styles.container}>
      {hasAggregate && (
        <Aggregate
          isSelected={activeSelection?.id === aggregateAddresses(list)}
          wallets={filteredWallets}
          onClick={() => {
            setActiveSelection({
              id: filteredWallets.map((wallet) => wallet.apiData.address).join('-'),
              list: tabId,
            });
          }}
        />
      )}
      {filteredWallets.length > 0 ? (
        filteredWallets.map((wallet) => (
          <Wallet
            address={wallet.apiData.address}
            authMethod={wallet.authMethod}
            balance={wallet.balanceUsd}
            ens={wallet.apiData.name}
            isLoggedInWallet={Boolean(wallet?.isLoggedInWallet)}
            isSelected={activeSelection?.id === wallet.apiData.address}
            key={wallet.apiData.address}
            onClick={(address: string) => {
              setActiveSelection({
                id: address,
                list: tabId,
              });
              push(portfolioURL.tokens(address));
            }}
          />
        ))
      ) : (
        <Empty
          cta={
            <ButtonPrimary
              disabled={list && !canAddNewWallet(list)}
              size="xs"
              onClick={() => setIsAddWalletModalOpen(true)}
            >
              <FontAwesomeIcon icon={faSearch} />
              Search wallets
            </ButtonPrimary>
          }
          text="Search for wallets by clicking the button below."
          title="No Wallets"
        />
      )}
    </div>
  );
};

import { isMagicAuth } from '@/components/accountSelector/utils/isMagicAuth';
import { UserWallet } from '@/components/manageWallets/libs/types';

// We want to filter out magic link addresses with no balance
export const filterMagicAddressWithNoBalance = (data: Array<UserWallet> | undefined) => {
  return data?.filter((wallet) => {
    if (isMagicAuth(wallet.authMethod)) {
      return wallet.balanceUsd > 0;
    }
    return true;
  });
};

import { popMessage } from 'moralis-ui';
import useBeanStore from '@/stores/beanStore';
import { api } from '@/utils/api';
import { errorFeedback } from '@/utils/l10n/generic';
import { logApp } from '@/utils/logApp';
import { BeanCollectUI } from './BeanCollectUI';

const log = logApp.create('BeansTemplate');

export const BeanCollectV2 = () => {
  const { setBeanState } = useBeanStore();
  const requestClaimBeans = async (): Promise<void> => {
    try {
      const claimBeansResponse = await api.user.userControllerClaimBeans();
      setBeanState({
        amount: claimBeansResponse.beanTotal,
        nextDate: claimBeansResponse.nextClaimDate,
      });
    } catch (error) {
      log.error('[requestClaimBeans] Error claiming beans', error);
      popMessage({
        variant: 'error',
        message: errorFeedback,
        title: 'Unable to Claim Beans',
      });
    }
  };

  return <BeanCollectUI handleClaimBeans={requestClaimBeans} />;
};

import clsx from 'clsx';
import { BeanCollectV2 } from '@/components/common/BeanCollectV2/BeanCollect';
import { useMainMenuFooter } from './hooks/useAsideMenuFooter';
import { MainMenuUserPlanPillUI } from './AsideMenuUserPlanPillUI';
import styles from './AsideMenuFooterUserInfo.module.scss';

export const AsideMenuFooterUserInfo = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { isFreeUser, hasHadTrial, planText } = useMainMenuFooter();

  return (
    <>
      <div className={clsx(styles.userInfo, { [styles.collapsed]: isCollapsed })}>
        <MainMenuUserPlanPillUI isFreeUser={isFreeUser} hasHadTrial={hasHadTrial} planText={planText} />
        {!isCollapsed && <BeanCollectV2 />}
      </div>
    </>
  );
};

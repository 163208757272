import { useState } from 'react';
import { useUserCoinFavourites } from '@/helpers';
import { faMinus, faPlus, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

export const useAsideMenuSavedTokens = (isLoggedIn: boolean) => {
  const { coinFavouritesData, isFetching, refetchCoinFavourites } = useUserCoinFavourites();
  const hasSavedTokens = coinFavouritesData && coinFavouritesData.length > 0;
  const [savedTokensOpen, setSavedTokensOpen] = useState(false);

  const toggleIcon = () => {
    if (savedTokensOpen) {
      return {
        spin: isFetching && isLoggedIn && !hasSavedTokens,
        icon: faMinus,
      };
    }
    if (!savedTokensOpen) {
      return {
        spin: isFetching && isLoggedIn && !hasSavedTokens,
        icon: isFetching && isLoggedIn && !hasSavedTokens ? faSpinnerThird : faPlus,
      };
    }
    return {
      spin: false,
      icon: faPlus,
    };
  };

  return {
    hasSavedTokens,
    isFetching,
    refetchSavedTokens: refetchCoinFavourites,
    savedTokens: coinFavouritesData,
    savedTokensOpen,
    setSavedTokensOpen,
    toggleIcon,
  };
};

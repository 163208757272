import { useEffect, useState } from 'react';
import { differenceInSeconds, fromUnixTime } from 'date-fns';

export const useCountdown = (nextDate: Date) => {
  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState(() => {
    const nowUnix = Math.floor(Date.now() / 1000);
    const now = fromUnixTime(nowUnix);
    return differenceInSeconds(nextDate, now);
  });

  useEffect(() => {
    const timerId = setInterval(() => {
      const nowUnix = Math.floor(Date.now() / 1000);
      const now = fromUnixTime(nowUnix);
      const remainingTime = differenceInSeconds(nextDate, now);

      setRemainingTimeInSeconds(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(timerId);
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [nextDate]);

  return remainingTimeInSeconds;
};

import { useRouter } from 'next/router';
import { Nav } from 'moralis-ui';
import { INavItemProps } from 'moralis-ui/dist/src/components/Nav/NavItem';
import { portfolioURL } from '@/components/portfolio/lib/portfolioURL';
import { useFormResetContext } from '@/helpers/hooks/formResetContext';
import { ERoutePath } from '@/utils/ERoutePath';
import { LINKS } from '@/utils/links';
import { faBullhorn, faChartPie, faCode, faCompass, faGridRound2, faHome } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const useAsideMenuData = (): INavItemProps[] => {
  const router = useRouter();
  const { setTriggerReset, setTriggerResetTable } = useFormResetContext();
  return [
    {
      children: 'Home',
      icon: <FontAwesomeIcon icon={faHome} width={16} />,
      id: 'home',
      isActive:
        router.pathname === ERoutePath.home ||
        router.pathname === ERoutePath.topTokensMarketCap ||
        router.pathname === ERoutePath.newCoins,
      onClick: () => {
        router.push(ERoutePath.home);
      },
    },
    {
      children: 'Portfolio',
      icon: <FontAwesomeIcon icon={faChartPie} width={16} />,
      isActive: router.pathname.startsWith('/portfolio'),
      id: 'portfolio',
      onClick: () => {
        router.push(portfolioURL.root);
      },
    },
    {
      children: 'Strategy Builder',
      icon: <FontAwesomeIcon icon={faCompass} width={16} />,
      id: 'strategy-builder',
      isActive: router.pathname === ERoutePath.tokenExplorer,
      onClick: () => {
        setTriggerReset(true);
        setTriggerResetTable(true);
        router.push(ERoutePath.tokenExplorer);
      },
    },
    {
      children: 'Advertise',
      icon: <FontAwesomeIcon icon={faBullhorn} width={16} />,
      id: 'advertise',
      onClick: () => {
        router.push(ERoutePath.marketplace);
      },
      isActive: router.pathname === ERoutePath.marketplace,
    },
    {
      children: 'Public API Docs',
      icon: <FontAwesomeIcon icon={faCode} width={16} />,
      id: 'public-api-docs',
      onClick: () => {
        window.open(LINKS.publicAPIDocs, '_blank');
      },
    },
    {
      children: 'More',
      icon: <FontAwesomeIcon icon={faGridRound2} width={16} />,
      id: 'more',
      isActive: router.pathname === ERoutePath.pricing || router.pathname === ERoutePath.blog,
      subItems: [
        <Nav.SubItem
          key={'pricing'}
          onClick={() => {
            router.push(ERoutePath.pricing);
          }}
          isActive={router.pathname === ERoutePath.pricing}
        >
          Pricing
        </Nav.SubItem>,
        <Nav.SubItem
          key="blog"
          onClick={() => {
            router.push(ERoutePath.blog);
          }}
          isActive={router.pathname === ERoutePath.blog}
        >
          Blog
        </Nav.SubItem>,
        <Nav.SubItem
          key="Affiliates"
          onClick={() => {
            window.open(LINKS.affiliate, '_blank');
          }}
        >
          Affiliates
        </Nav.SubItem>,
      ],
    },
  ];
};

import { UserWallet } from '@/components/manageWallets/libs/types';
import { isNullish } from '../isNullish';
import { logApp } from '../logApp';

const log = logApp.create('extractSingleWalletBalance');

export const extractSingleWalletBalance = (list: UserWallet[], wallet: string) => {
  if (!list?.length || !wallet) {
    log.warnError('[extractSingleWalletBalance]', 'Invalid input', {
      list,
      wallet,
    });
    return 0;
  }

  const normalizedWallet = wallet.toLowerCase();
  const balance = list.find((item) => item.apiData.address.toLowerCase() === normalizedWallet)?.balanceUsd;

  if (isNullish(balance)) {
    log.warnError('[extractSingleWalletBalance]', 'User wallet not found in list', {
      list,
      wallet,
    });
    return 0;
  }

  return balance;
};

import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useAuthMethodWithBalance } from '@/helpers/hooks/useAuthMethodWithBalance';
import { formatCurrencyWithoutRounding, truncate } from '@/utils/fmt';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlockiesAvatar } from '../common/BlockiesAvatar';
import { portfolioURL } from '../portfolio/lib/portfolioURL';
import { isSelectingOption } from './utils/isSelectingOption';
import { WalletOptions } from './WalletOptions';
import styles from './Wallet.module.scss';

export const Wallet = ({
  authMethod,
  isLoggedInWallet,
  address,
  balance,
  isSelected,
  onClick,
  ens,
}: {
  address: string;
  authMethod?: string;
  balance: number;
  ens?: string;
  isLoggedInWallet: boolean;
  isSelected: boolean;
  onClick: (address: string) => void;
}) => {
  const { push } = useRouter();
  const { isMagicAutWithoutBalance, getUserEmail } = useAuthMethodWithBalance({
    authMethod,
    balance,
  });

  return (
    <div
      className={clsx(styles.container, {
        [styles.selected]: isSelected,
        [styles.notClickable]: isMagicAutWithoutBalance,
      })}
    >
      <div
        className={styles.inner}
        role="button"
        onClick={(e) => {
          // Check if the click was on the ellipsis icon, if so, do nothing
          if (isSelectingOption(e)) return;
          onClick(address);
        }}
      >
        <BlockiesAvatar address={address} className={clsx(styles.blockie, styles.noUserSelect)} />

        <span className={styles.info}>
          {isMagicAutWithoutBalance ? (
            <p className={clsx(styles.noUserSelect, styles.id)}>{getUserEmail()}</p>
          ) : ens ? (
            <p className={clsx(styles.noUserSelect)}>{ens}</p>
          ) : (
            <p className={clsx(styles.noUserSelect)}>{truncate(address)}</p>
          )}

          <span className={clsx(styles.noUserSelect, styles.walletDetails)}>
            {formatCurrencyWithoutRounding(balance, undefined, undefined, 2)}
            {isLoggedInWallet && (
              <p className={styles.loggedInText}>
                {' • '}
                Logged In
              </p>
            )}
          </span>
        </span>
      </div>

      <div className={styles.icons}>
        {isSelected && <FontAwesomeIcon height={11} icon={faCheck} width={11} />}
        {!isMagicAutWithoutBalance ? (
          <WalletOptions
            ens={ens}
            id={address}
            isLoggedInAccount={isLoggedInWallet}
            onViewPortfolio={() => push(portfolioURL.tokens(address))}
          />
        ) : null}
      </div>
    </div>
  );
};

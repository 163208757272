import { useRef } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { Empty, Flex } from 'moralis-ui';
import { CoinLogo } from '@/components/common/CoinLogo';
import useOnClickOutside from '@/helpers/hooks/useOnClickOutside';
import { Coin } from '@/services';
import { formatNumber, formatTokenPrice } from '@/utils/fmt';
import { getTokenURLById } from '@/utils/urls';
import { faArrowsRotate, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsideMenuSavedTokens } from './hooks/useAsideMenuSavedTokens';
import styles from './AsideMenuSavedTokens.module.scss';

export const AsideMenuSavedTokens = ({ isCollapsed, isLoggedIn }: { isCollapsed: boolean; isLoggedIn: boolean }) => {
  const {
    savedTokens,
    isFetching,
    refetchSavedTokens,
    toggleIcon,
    hasSavedTokens,
    savedTokensOpen,
    setSavedTokensOpen,
  } = useAsideMenuSavedTokens(isLoggedIn);

  const handleToggleTokensOpen = () => {
    setSavedTokensOpen(!savedTokensOpen);
  };
  const floatingDropdownRef = useRef(null);

  const closeFloatingTokens = () => {
    setSavedTokensOpen(false);
  };

  useOnClickOutside(floatingDropdownRef, isCollapsed ? closeFloatingTokens : undefined, 'collapseSidebarIcon');

  const handleRefreshTokens = () => {
    refetchSavedTokens();
  };

  return (
    <div className={clsx(styles.wrapper, { [styles.collapsed]: isCollapsed })} ref={floatingDropdownRef}>
      <div className={styles.header}>
        <div
          className={clsx(styles.title, {
            [styles.collapsed]: isCollapsed,
            [styles.open]: isCollapsed && savedTokensOpen,
          })}
        >
          <FontAwesomeIcon
            icon={isCollapsed && isFetching && isLoggedIn ? faSpinnerThird : faHeart}
            height={14}
            className={clsx(styles.heartIcon, {
              [styles.collapsed]: isCollapsed,
              [styles.disabled]: isCollapsed && isFetching && isLoggedIn,
            })}
            onClick={isCollapsed ? handleToggleTokensOpen : undefined}
            spin={isCollapsed && isFetching && isLoggedIn}
          />
          {!isCollapsed && 'Saved Tokens'}
        </div>
        {((savedTokensOpen && hasSavedTokens && !isCollapsed && isLoggedIn) ||
          (!savedTokensOpen && hasSavedTokens && !isCollapsed && isLoggedIn && isFetching)) && (
          <button
            disabled={isFetching}
            className={clsx(styles.toggleButton, { [styles.disabled]: isFetching && isLoggedIn })}
            type="button"
            onClick={handleRefreshTokens}
          >
            <FontAwesomeIcon
              icon={isFetching ? faSpinnerThird : faArrowsRotate}
              spin={isFetching && isLoggedIn}
              height={14}
            />
          </button>
        )}

        {!isCollapsed && (
          <button
            disabled={toggleIcon().spin}
            className={clsx(styles.toggleButton, {
              [styles.open]: savedTokensOpen,
              [styles.disabled]: toggleIcon().spin,
            })}
            type="button"
            onClick={handleToggleTokensOpen}
          >
            <FontAwesomeIcon icon={toggleIcon().icon} height={14} spin={toggleIcon().spin} />
          </button>
        )}
      </div>
      <div
        className={clsx(styles.tokensWrapper, {
          [styles.open]: savedTokensOpen,
          [styles.floatingTokensWrapper]: isCollapsed,
        })}
      >
        {isCollapsed && <span className={styles.floatingTitle}>Saved Tokens</span>}
        {!hasSavedTokens || !isLoggedIn ? (
          <Empty
            className={styles.empty}
            text={isLoggedIn ? 'You have not saved any tokens' : 'Login to see your saved tokens'}
          />
        ) : (
          <ul
            className={clsx(styles.tokenList, {
              [styles.open]: savedTokensOpen,
            })}
          >
            {savedTokens?.map((token) => {
              return <AsideSavedToken key={token.chainId_coinTokenAddress} token={token} />;
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const AsideSavedToken = ({ token }: { token: Coin }) => {
  const address = token.chainId_coinTokenAddress.split('_').pop() as string;
  const tokenSingleAddress = getTokenURLById({
    chainId: token.chainId,
    tokenAddress: address,
    uiniqueName: token?.uniqueName,
  });

  return (
    <Link key={token.chainId_coinTokenAddress} className={styles.token} href={tokenSingleAddress}>
      <div className={styles.tokenInfo}>
        <CoinLogo name={token.name || token.symbol || 'NA'} size="small" src={token.logo} className={styles.logo} />
        <Flex direction="column">
          <span className={styles.savedTokenAddress}>{token.symbol}</span>
          <span className={styles.savedTokenChain}>{token.chainId}</span>
        </Flex>
      </div>
      <Flex direction="column" align="flex-end">
        <span
          className={clsx(styles.savedTokenPriceChange, {
            [styles.positive]: token.OneDayPricePercentChangeUSD > 0,
            [styles.negative]: token.OneDayPricePercentChangeUSD < 0,
          })}
        >
          {(token.OneDayPricePercentChangeUSD < 0 ? '' : '-') +
            formatNumber(token.OneDayPricePercentChangeUSD, 2, 2) +
            '%'}
        </span>
        <span className={styles.savedTokenPriceUSD}>{formatTokenPrice(token.PriceUSD)}</span>
      </Flex>
    </Link>
  );
};

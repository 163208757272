import clsx from 'clsx';
import { isAddress } from 'ethers/lib/utils';
import { BlockiesAvatar } from '@/components/common/BlockiesAvatar';
import { extractMultiWalletBalance } from '@/utils/accountSelector/extractMultiWalletBalance';
import { extractSingleWalletBalance } from '@/utils/accountSelector/extractSingleWalletBalance';
import { capitalize, formatCurrencyWithoutRounding, splitCamelCase, truncate } from '@/utils/fmt';
import { probeEthAddressSeries } from '@/utils/validateStringAsEthAddresses';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EPortfolioList } from '../manageWallets/libs/types';
import { TList } from '../manageWallets/utils/organizeWalletsByList';
import { useSelectedAccount } from './hooks/useSelected';
import styles from './CurrentlySelected.module.scss';

const CurrentlySelected = ({
  onClick,
  lists,
  tabId,
  isCollapsed,
}: {
  lists: TList;
  onClick: () => void;
  tabId: EPortfolioList;
  isCollapsed?: boolean;
}) => {
  const { currentlySelectedId, displayId, activeListName } = useSelectedAccount(lists || [], tabId);
  const isAggregate = probeEthAddressSeries(currentlySelectedId);
  const displayedId = !isAggregate ? displayId : activeListName;

  /** Computing currently selected balance (both list and single wallet)  */
  const list = lists[activeListName === 'My Wallets' ? EPortfolioList.MY : EPortfolioList.WATCHED];
  const aggregatedNetWorth = extractMultiWalletBalance(list);
  const selectedAccountBalance = isAggregate
    ? aggregatedNetWorth
    : extractSingleWalletBalance(list, currentlySelectedId);

  return (
    <div
      className={clsx(styles.container, { [styles.collapsed]: isCollapsed })}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <div className={styles.inner}>
        {currentlySelectedId && (
          <BlockiesAvatar
            address={currentlySelectedId}
            className={clsx(styles.blockie, { [styles.collapsed]: isCollapsed })}
          />
        )}
        {!isCollapsed && (
          <span className={styles.span}>
            <span className={styles.selectedInfo}>
              {displayedId && isAddress(displayedId) ? (
                <span className={styles.authMethod}>
                  <p className={styles.id}>{truncate(currentlySelectedId)}</p>
                  <p className={styles.selectedNetWorth}>
                    {formatCurrencyWithoutRounding(selectedAccountBalance, undefined, undefined, 2)}
                  </p>
                </span>
              ) : (
                <span className={styles.authMethod}>
                  {displayedId && (
                    <p className={styles.id}>{!isAggregate ? displayedId : capitalize(splitCamelCase(displayedId))}</p>
                  )}
                  <p className={styles.selectedNetWorth}>
                    {formatCurrencyWithoutRounding(selectedAccountBalance, undefined, undefined, 2)}
                  </p>
                </span>
              )}
            </span>
          </span>
        )}
      </div>
      {!isCollapsed && (
        <div>
          <FontAwesomeIcon height={16} icon={faEllipsis} width={16} />
        </div>
      )}
    </div>
  );
};

export default CurrentlySelected;

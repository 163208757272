const ETH_ADDRESS_LENGTH = 42;

/**
 * @notice We cannot simply check if all addresses as valid (isAddress) because BE
 *         creates invalid addresses when user register using anon checkout flow
 */
export const probeEthAddressSeries = (str: string) => {
  str = removeSeparators(str);
  return str.length > ETH_ADDRESS_LENGTH && str.length % ETH_ADDRESS_LENGTH === 0;
};

const removeSeparators = (str: string) => str.replace(new RegExp('-', 'g'), '');

import clsx from 'clsx';
import { Loader, Skeleton } from 'moralis-ui';
import styles from './AccountSelector.module.scss';

export const AccountSelectorSkeleton = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  return (
    <div className={clsx(styles.skeletonWrapper, { [styles.skeletonCollapsed]: isCollapsed })}>
      <div className={styles.skeletonInner}>
        <div className={styles.skeletonAvatar}>
          {isCollapsed ? (
            <div className={styles.skeletonLoader}>
              <Loader />
            </div>
          ) : (
            <Skeleton type="rectangle" />
          )}
        </div>
        {!isCollapsed && (
          <>
            <div className={styles.skeletonInfo}>
              <div className={styles.skeletonAddress}>
                <Skeleton type="rectangle" borderRadius={2} />
              </div>
              <div className={styles.skeletonNetWorth}>
                <Skeleton type="rectangle" borderRadius={2} />
              </div>
            </div>
            <div className={styles.skeletonLoader}>
              <Loader />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

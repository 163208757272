/**
 * @description Make sure that the currently selected wallet is always displayed in the account selector
 *              and updates, or fallback to the `logged in account`, whenever the user triggers a change in the wallet list.
 *
 * Possible states of the currently selected account are:
 *
 * 1) One wallet is selected ✅
 * 2) Aggregate wallets are selected ✅
 *
 *
 * 3) No wallet is selected ❌
 * 4) A wallet is selected and is not part of a list anymore (e.g. removed from the list) ❌
 * 5) Aggregate wallets are selected but the user moves these wallets to a different list ❌
 * 6) No list is selected ❌
 */

import { useEffect } from 'react';
import { EPortfolioList, portfolioListToTab } from '@/components/manageWallets/libs/types';
import { aggregateAddresses, getMergedList } from '@/components/manageWallets/utils/list';
import { TList } from '@/components/manageWallets/utils/organizeWalletsByList';
import useUserStore from '@/stores/userStore';
import { logApp } from '@/utils/logApp';
import { probeEthAddressSeries } from '@/utils/validateStringAsEthAddresses';
import { useAccountSelectorStore } from '../utils/accountSelectorStore';
import { isMagicAuth } from '../utils/isMagicAuth';

const log = logApp.create('useSelectedAccount');
export const useSelectedAccount = (lists: TList, tabId: EPortfolioList) => {
  const user = useUserStore((state) => state.user);
  const { activeSelection, setActiveSelection } = useAccountSelectorStore();
  const list = lists[tabId];

  const mergedLists = getMergedList(lists);

  const loggedInId = mergedLists.find((wallet) => wallet.isLoggedInWallet)?.apiData.address;

  useEffect(() => {
    if (!loggedInId) {
      log.warn('Missing "logged in" address', { lists, tabId });
      return;
    }

    // UNHAPPY PATHS //

    // 3) No wallet is selected ❌
    if (!activeSelection?.id) {
      setActiveSelection({ id: loggedInId, list: EPortfolioList.MY });
      return;
    }

    // 6) No list is selected ❌
    if (!activeSelection?.list) {
      setActiveSelection({ id: loggedInId, list: EPortfolioList.WATCHED });
      return;
    }

    // We can now safely assume that activeSelection.id is defined
    const activeId = activeSelection.id;

    // We can now safely assume that activeSelection.list is defined
    const activeList = activeSelection.list;

    // 5) Aggregate wallets are selected but the user moves these wallets to a different list ❌
    if (probeEthAddressSeries(activeId)) {
      const aggregated = aggregateAddresses(lists[activeList]);

      // Aggregate wallets are selected ✅
      if (aggregated && probeEthAddressSeries(aggregated)) {
        setActiveSelection({ id: aggregated, list: activeList });
        return;
      } else {
        // If the aggregated address series is invalid, fallback to the logged in account
        setActiveSelection({ id: loggedInId, list: EPortfolioList.MY });
        return;
      }
    }

    // 4) A wallet is selected and is not part of a list anymore (e.g. removed from the list) ❌
    const wallet = mergedLists.find((wallet) => wallet.apiData.address === activeId);

    // If the wallet is not found in the merged lists, fallback to the logged in account
    if (!wallet) {
      setActiveSelection({ id: loggedInId, list: EPortfolioList.WATCHED });
    }
  }, [list, activeSelection?.id, activeSelection?.list]);

  const getDisplayId = () => {
    if (!activeSelection?.id || !list) return undefined;

    const wallet = mergedLists.find((wallet) => wallet.apiData.address === activeSelection.id);

    if (isMagicAuth(wallet?.authMethod)) {
      return user?.email;
    }

    // Check for ens name
    const ens = wallet?.apiData.name;
    if (ens) return ens;
    return activeSelection.id;
  };

  const getConnectorName = (address?: string) => {
    if (!address) return null;

    // Check if the address is part of an aggregated list.
    const isProvenAddressSeries = probeEthAddressSeries(address);
    if (isProvenAddressSeries) return 'Aggregated';

    // Find wallet in the main data.
    const wallet = mergedLists.find((wallet) => wallet.apiData.address === activeSelection?.id);
    if (wallet) return wallet.apiData.settings?.additionMethod;
  };

  return {
    displayId: getDisplayId(),
    currentlySelectedId: activeSelection?.id || '',
    connectorName: getConnectorName(activeSelection?.id),
    activeListName: activeSelection?.list ? portfolioListToTab(activeSelection.list) : null,
  };
};

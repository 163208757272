import { getCookie, setCookie } from 'cookies-next';
import { ALL_FEATURE_FLAGS, FeatName } from '../featureFlag/ALL_FEATURE_FLAGS';
import { log } from '../featureFlag/log';
import { parseFeatFlagDictSafe } from '../featureFlag/parseFeatFlagDictSafe';
import { isValidFeatureName, readFeatureValueSafe } from '../featureFlag/validate';

/**
 * /!\ Important: Don't forget cookie consent
 * to work in prod this cookie name should be allowed as "strictly necessary cookies"
 */
export const FEATURE_FLAG_COOKIE_NAME = 'featureFlags';

const parseFeatFlagDictFromCookies = (): Record<string, unknown> => {
  const cookieVal = getCookie(FEATURE_FLAG_COOKIE_NAME);
  if (!cookieVal) {
    return {};
  }
  return parseFeatFlagDictSafe(cookieVal);
};

export const setFeatFlagToCookie = (featureName: string, value: string): void => {
  if (!isValidFeatureName(featureName)) {
    log.warn('Invalid feature name', { featureName, knownFeatureNames: Object.keys(ALL_FEATURE_FLAGS) });
    return;
  }

  try {
    ALL_FEATURE_FLAGS[featureName].parse(value); // Validate the value
    // Update the cookie
    const currentFlags = parseFeatFlagDictFromCookies();
    currentFlags[featureName] = value;
    setCookie(FEATURE_FLAG_COOKIE_NAME, JSON.stringify(currentFlags));
  } catch (err) {
    log.warnError('Failed to set feature flag', err, { featureName, value });
  }
};

/**
 * Can be used in SSR too
 */
export const getFeatFlagFromCookie = <K extends FeatName>(featureName: K) => {
  const ffDict = parseFeatFlagDictFromCookies();
  return readFeatureValueSafe(featureName, ffDict);
};

import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Pill } from 'moralis-ui';
import { ERoutePath } from '@/utils/ERoutePath';
import styles from './AsideMenuUserPlanPillUI.module.scss';

export const MainMenuUserPlanPillUI = ({
  isFreeUser,
  hasHadTrial,
  planText,
}: {
  isFreeUser: boolean;
  hasHadTrial: boolean;
  planText: string;
}) => {
  const router = useRouter();

  return (
    <div
      className={clsx(styles.user, { [styles.pointer]: isFreeUser && !hasHadTrial })}
      onClick={isFreeUser && !hasHadTrial ? () => router.push(ERoutePath.pricing) : () => null}
    >
      <Pill color={isFreeUser && !hasHadTrial ? 'green' : 'navy'} size="xs">
        {planText}
      </Pill>
    </div>
  );
};

import clsx from 'clsx';
import { ButtonLink, Copy, Dropdown } from 'moralis-ui';
import { useLogout } from '@/helpers/hooks';
import { ERoutePath } from '@/utils/ERoutePath';
import { truncate } from '@/utils/fmt';
import { faPieChart, faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './WalletOptions.module.scss';

export const WalletOptions = ({
  id,
  isLoggedInAccount,
  onViewPortfolio,
  ens,
}: {
  ens?: string;
  id?: string;
  isLoggedInAccount: boolean;
  onViewPortfolio: () => void;
}) => {
  const { handleLogout } = useLogout();

  return (
    <Dropdown
      position="bottom"
      size="sm"
      trigger={<FontAwesomeIcon height={12} icon={faEllipsis} role="button" width={12} />}
    >
      <div className={styles.container}>
        {id && (
          <div className={clsx(styles.id, 'color-dropdown-active')}>
            <span>
              <p className="body-12-regular">{truncate(id, 16)}</p>
              <Copy text={id} />
            </span>
            {ens && (
              <span>
                <p className={clsx('caption-12-regular', 'color-headline-secondary')}>{ens}</p>
                <Copy text={ens} />
              </span>
            )}
          </div>
        )}
        <div className={styles.item}>
          <ButtonLink size="xs" onClick={onViewPortfolio}>
            <FontAwesomeIcon icon={faPieChart} />
            View Portfolio
          </ButtonLink>
        </div>

        {isLoggedInAccount && (
          <div className={styles.item}>
            <ButtonLink
              size="xs"
              onClick={() =>
                handleLogout({
                  notification: {
                    message: 'Come back tomorrow to collect your beans!',
                    title: 'Logged Out',
                    type: 'success',
                  },
                  redirectUrl: ERoutePath.home,
                })
              }
            >
              <FontAwesomeIcon icon={faRightFromBracket} />
              Logout
            </ButtonLink>
          </div>
        )}
      </div>
    </Dropdown>
  );
};

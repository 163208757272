import { useLayoutEffect, useState } from 'react';
import { useLogout } from '@/helpers/hooks';
import useAuthStore from '@/stores/authStore';
import useMenuStore from '@/stores/menuStore';
import useUserStore from '@/stores/userStore';
import {
  ENTERPRISE_PLAN_TYPE_ID,
  FREE_PLAN_TYPE_ID,
  PRO_PLAN_TYPE_ID,
  STAFF_PLAN_TYPE_ID,
  STARTER_PLAN_TYPE_ID,
} from '@/utils/planTypeId';
import {
  ENTERPRISE_PLAN_ID_TO_NAME,
  FREE_PLAN_ID_TO_NAME,
  PRO_PLAN_ID_TO_NAME,
  STAFF_PLAN_ID_TO_NAME,
  STARTER_PLAN_ID_TO_NAME,
} from '@/utils/planTypeId';

const START_TRIAL_TEXT = 'START TRIAL';

const getTextByLevel = (level: number) => {
  switch (level) {
    case STARTER_PLAN_TYPE_ID:
      return STARTER_PLAN_ID_TO_NAME;
    case PRO_PLAN_TYPE_ID:
      return PRO_PLAN_ID_TO_NAME;
    case STAFF_PLAN_TYPE_ID:
      return STAFF_PLAN_ID_TO_NAME;
    case ENTERPRISE_PLAN_TYPE_ID:
      return ENTERPRISE_PLAN_ID_TO_NAME;
    default:
      return FREE_PLAN_ID_TO_NAME;
  }
};

export const useMainMenuFooter = () => {
  const auth = useAuthStore((state) => state.authToken);
  const { handleLogout } = useLogout();
  const { user, stage: userStage } = useUserStore();
  const toggleIsCollapsed = useMenuStore((state) => state.toggleIsCollapsed);

  const isLoggedIn = Boolean(auth);

  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    setIsLoading(false);
  }, [auth, isLoggedIn]);

  const isFreeUser = !userStage || userStage.level <= FREE_PLAN_TYPE_ID;
  const hasHadTrial = user?.purchasedTrialPlan ?? false;

  const planText =
    isFreeUser && !hasHadTrial ? START_TRIAL_TEXT : getTextByLevel(userStage?.level ?? FREE_PLAN_TYPE_ID);

  return {
    handleLogout,
    user,
    userStage,
    isLoggedIn,
    isLoading,
    toggleIsCollapsed,
    isFreeUser,
    hasHadTrial,
    planText,
  };
};

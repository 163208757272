import { useRouter } from 'next/router';
import { ButtonOutline, ButtonPrimary } from 'moralis-ui';
import useAuthStore from '@/stores/authStore';
import { ERoutePath } from '@/utils/ERoutePath';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { manageWalletStore } from '../manageWallets/stores/manageWalletStores';
import styles from './Buttons.module.scss';

export const Buttons = ({ isLoading, canAddNewWallet }: { canAddNewWallet: boolean; isLoading: boolean }) => {
  const { push } = useRouter();
  const isLoggedIn = useAuthStore((state) => Boolean(state.authToken));
  const setIsAddWalletModalOpen = manageWalletStore((state) => state.setIsAddWalletModalOpen);

  return (
    <div className={styles.buttons} onMouseDown={(e) => e.stopPropagation()}>
      <ButtonPrimary
        disabled={!canAddNewWallet}
        id="connect-button"
        isLoading={isLoading}
        size="sm"
        onClick={() => setIsAddWalletModalOpen(true)}
      >
        <FontAwesomeIcon icon={faPlus} /> Add New Wallet
      </ButtonPrimary>

      {isLoggedIn && (
        <ButtonOutline size="sm" onClick={() => push(ERoutePath.manageWallets)}>
          Manage Wallets
        </ButtonOutline>
      )}
    </div>
  );
};

import { createFeatureFlag, FeatValidatorCfg } from './createFeatureFlag';
import { GenericFeatCfg } from './validate';

export const booleanFeature: FeatValidatorCfg<boolean> = createFeatureFlag(false, (val) =>
  val === '' ? !val : val === 'true',
);

/**
 * Add your flags here
 * @see ./examples.ts for reference
 */
export const ALL_FEATURE_FLAGS = {
  /**
   * Legacy token pages
   */
  tokenSingleLegacy: booleanFeature,
  tokenPairSelector: booleanFeature,
  trading: booleanFeature,
  sidebar: booleanFeature,
} satisfies GenericFeatCfg;

// Utils
export type AllFeatureFlags = typeof ALL_FEATURE_FLAGS;
export type FeatName = keyof AllFeatureFlags;
export type FeatValue<K extends FeatName> = AllFeatureFlags[K] extends FeatValidatorCfg<infer V> ? V : never;

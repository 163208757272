import { ALL_FEATURE_FLAGS, FeatName, FeatValue } from './ALL_FEATURE_FLAGS';
import { FeatValidatorCfg } from './createFeatureFlag';
import { log } from './log';

export type GenericFeatCfg = Record<string, FeatValidatorCfg<unknown>>;

export const isValidFeatInput = (val: unknown): val is string => typeof val === 'string';
export const isValidFeatureName = (name: string): name is FeatName => name in ALL_FEATURE_FLAGS;

// This function intentionally not exported
// to not confuse ffDict and ffDict[featureName]
// if by mistake one would pass the whole dictionary insted, feature always would be
const parseFeatureValueSafe = <K extends FeatName>(featureName: K, value: unknown) => {
  const cfg = ALL_FEATURE_FLAGS[featureName] as FeatValidatorCfg<FeatValue<K>>;
  if (!isValidFeatInput(value)) {
    return cfg.default;
  }
  try {
    return cfg.parse(value);
  } catch (error) {
    log.warnError('Failed to validate feature flag', error, { featureName, value });
  }
  return cfg.default;
};

export const readFeatureValueSafe = <K extends FeatName>(featureName: K, ffDict: Record<string, unknown>) =>
  parseFeatureValueSafe(featureName, ffDict[featureName]);

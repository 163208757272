export const LINKS = {
  affiliate: 'https://affiliate.moralismoney.com/',
  devsWanted:
    'https://talent.moralis.io/jobs/1995445-backend-engineer-blockchain-data/7d24707f-1854-47f2-8162-1bafb053fa9a',
  discord: 'https://discord.gg/WPGzq2brKB',
  docs: 'https://docs.moralis.io/',
  enterpriseForm: 'https://moralis.typeform.com/to/G7Q7xe7R',
  faq: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#-welcome-to-moralis-money-faq',
  helpCenter: 'https://money-help.moralis.io/en/',
  howFiltersWork: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#how-do-filters-work',
  legal: 'https://drive.google.com/drive/u/1/folders/1zVV07zI4GU20_Jp2pt2wIwaRQrDc0P3c',
  mailTo: 'money@moralis.io',
  moneyLineDocs:
    'https://money-help.moralis.io/en/articles/108369-how-to-find-the-money-line-indicator-on-my-tradingview-account-how-to-set-up-alerts',
  moralisMoneyGetStarted:
    'https://start.moralismoney.com/optin3s4xdq9i?utm_source=moralismoney&utm_campaign=get-tutorial&utm_medium=card',
  moralisMoneyHome: 'https://moralis.com/',
  readme_GettingStarted:
    'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/affiliates/welcome%20and%20faq.md#-how-to-get-started',
  readme_HowToUse: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#using-moralis-money',
  readme_Value10x:
    'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/README.md#-why-will-moralis-money-10x-in-value-over-the-coming-months',
  requestFeatureForm: 'https://moralis.typeform.com/to/wLtl249j',
  startMoralisMoney: 'start.moralismoney.com',
  support: 'https://money-help.moralis.io',
  supportLinkText: 'Moralis support center',
  terms: '#',
  verified: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/verified.md#background',
  verify: 'https://github.com/MoralisWeb3/moralis-money-faq/blob/main/verified.md#how-to-get-your-coin-verified',
  publicAPIDocs: 'https://docs.moralis.com/?utm_source=moralis-money&utm_medium=mm-sidebar',
  twitter: 'https://twitter.com/moraliscom',
  telegram: 'https://t.me/moralisweb3',
  youtube: 'https://www.youtube.com/@Moraliscom',
};

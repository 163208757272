import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { Nav } from 'moralis-ui';
import { useSearchStore } from '@/components/SearchV2/hooks/useSearchStore';
import useAuthStore from '@/stores/authStore';
import { useAsideMenuData } from './hooks/useAsideMenuData';
import { AsideMenuFooterUserInfo } from './AsideMenuFooterUserInfo';
import { AsideMenuLogo } from './AsideMenuLogo';
import { AsideMenuSavedTokens } from './AsideMenuSavedTokens';
import { AsideSearchInput } from './AsideSearchInput';
import styles from './AsideMenu.module.scss';

const AsideMenuFooterUI = dynamic<{ isCollapsed: boolean }>(
  () => import('@/components/aside/AsideMenuV2/AsideMenuFooterUI').then((mod) => mod.AsideMenuFooterUI),
  {
    ssr: false,
  },
);

export const AsideMenu = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { toggleSearchModal } = useSearchStore();
  const menuItems = useAsideMenuData();
  const isLoggedIn = useAuthStore((state) => Boolean(state.authToken));
  return (
    <aside
      className={clsx(styles.mainMenu, { [styles.collapsed]: isCollapsed })}
      id={isCollapsed ? 'sidebarCollapsed' : 'sidebarExpanded'}
    >
      <div className={styles.main}>
        <div className={styles.logo}>
          <AsideMenuLogo isCollapsed={isCollapsed} />
        </div>
        <AsideSearchInput onClickOpenSearch={toggleSearchModal} isCollapsed={isCollapsed} />

        <div className={styles.inner}>
          <div className={styles.innerMainNav}>
            <Nav
              isCollapsed={isCollapsed}
              variant="filled"
              className={clsx(styles.navMenu, { [styles.collapsed]: isCollapsed })}
            >
              {menuItems.map((item) => (
                <Nav.Item
                  key={item.id}
                  icon={item.icon}
                  isActive={item.isActive}
                  onClick={item.onClick}
                  subItems={item.subItems}
                >
                  {item.children}
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <AsideMenuSavedTokens isCollapsed={isCollapsed} isLoggedIn={isLoggedIn} />
        </div>
      </div>
      <div className={styles.footer}>
        {isLoggedIn && <AsideMenuFooterUserInfo isCollapsed={isCollapsed} />}
        <AsideMenuFooterUI isCollapsed={isCollapsed} />
      </div>
    </aside>
  );
};

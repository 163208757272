import { useCallback, useMemo, useState } from 'react';
import {
  DEV_MODE_SPELL,
  disableDevMode,
  enableDevMode,
  isDevModeEnabled,
  isValidDevModeCode,
  NO_DEVMODE_SPELL,
} from '@/utils/devMode';
import { FeatName } from '@/utils/featureFlag/ALL_FEATURE_FLAGS';
import { getFeatFlagFromCookie, setFeatFlagToCookie } from '@/utils/nextjs/cookies';

export const useFeatureFlag = <K extends FeatName>(flag: K) => useMemo(() => getFeatFlagFromCookie(flag), [flag]);

const parseInputValue = (value: string) => {
  if (!value) {
    return;
  }

  if (!isDevModeEnabled()) {
    if (isValidDevModeCode(value)) {
      enableDevMode();
    }
    return;
  }

  if (value === NO_DEVMODE_SPELL) {
    disableDevMode();
    return;
  }

  const [feature, arg] = value.split(' ');
  setFeatFlagToCookie(feature, arg ?? '');
  window.location.reload();
};

export const useFeatureFlagEdit = () => {
  const [isFlagModeEnabled, setIsFlagModeEnabled] = useState(false);

  const handleInputKeyDown = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (key === 'Shift' && value === '' && isDevModeEnabled()) {
        setIsFlagModeEnabled((prev) => !prev);
      }
      if (key === 'Enter' && isFlagModeEnabled) {
        parseInputValue(value);
      }

      if (key === 'Enter' && value === DEV_MODE_SPELL) {
        enableDevMode();
      }
    },
    [isFlagModeEnabled],
  );

  return { handleInputKeyDown, parseInputValue, isFlagModeEnabled };
};

export type TTokenExplorerTimeframes = 'oneWeekAndAbove' | 'oneDayAndAbove' | 'tenMinsAndAbove';
export type TCoinAgeTimeframes = 'sevenDaysAndAbove' | 'all';

export interface IBenefitItems {
  /** Whether advances security score is available */
  advancedSecurityScore: boolean;
  /** Whether all coin ages are available */
  coinAge: TCoinAgeTimeframes;
  /** Whether API access is available */
  api: boolean;
  /** The number of daily beans. If 0, it is not available, if -1, it is unlimited
   * @default 0
   */
  dailyBeans: number;
  /** Whether fast support is available */
  fastSupport: boolean;
  /** Whether forward strategy testing is available */
  forwardStrategyTesting: boolean;
  /** Whether institutional research is available */
  institutionalResearch: boolean;
  /** Whether market live streams are available */
  marketLiveStreams: boolean;
  /** Whether moralis line trend indicator is available */
  moneyLineTrendIndicator: boolean;
  /** Whether on-chain strength index is available */
  onChainStrengthIndex: boolean;
  /** Whether premium education is available */
  premiumEducation: boolean;
  /** Whether access to private Discord community is available */
  privateDiscordCommunity: boolean;
  /** Whether service level agreement (SLA) is available */
  sla: boolean;
  /** The number of strategy alerts available. If 0, it is not available, if -1, it is unlimited */
  strategyAlerts: number;
  /** The number of token alerts. If 0, it is not available, if -1, it is unlimited */
  tokenAlerts: number;
  /** The different timeframes available on token explorer */
  tokenExplorerTimeframes: TTokenExplorerTimeframes;
  /** Whether top tokens by market cap are available */
  topTokensMarketCap: boolean;
  /** Whether whale activity alerts are available */
  whaleActivityAlerts: boolean;
  /** Whether trending tokens are available */
  trendingTokens?: boolean;
  /** Whether real-time tokens are available */
  realTimeTokens?: boolean;
}

export type IBenefits = {
  [key: number]: IBenefitItems;
};

const defaultBenefits: IBenefitItems = {
  advancedSecurityScore: true,
  coinAge: 'sevenDaysAndAbove',
  api: false,
  dailyBeans: 0,
  fastSupport: true,
  forwardStrategyTesting: true,
  institutionalResearch: false,
  marketLiveStreams: false,
  moneyLineTrendIndicator: false,
  onChainStrengthIndex: false,
  premiumEducation: false,
  privateDiscordCommunity: false,
  realTimeTokens: true,
  sla: false,
  strategyAlerts: 3,
  tokenAlerts: 0,
  tokenExplorerTimeframes: 'oneWeekAndAbove',
  topTokensMarketCap: true,
  trendingTokens: true,
  whaleActivityAlerts: false,
};

export const filteredBenefits: { [key: number]: Partial<IBenefitItems> } = {
  // FREE
  1: {
    ...defaultBenefits,
    dailyBeans: 500,
  },
  // STARTER
  20: {
    ...defaultBenefits,
    coinAge: 'all',
    dailyBeans: 1000,
    moneyLineTrendIndicator: true,
    onChainStrengthIndex: true,
    strategyAlerts: 5,
    tokenAlerts: 15,
    tokenExplorerTimeframes: 'oneDayAndAbove',
    whaleActivityAlerts: true,
  },
  // PRO
  40: {
    ...defaultBenefits,
    advancedSecurityScore: true,
    coinAge: 'all',
    dailyBeans: 1250,
    marketLiveStreams: true,
    moneyLineTrendIndicator: true,
    onChainStrengthIndex: true,
    premiumEducation: true,
    privateDiscordCommunity: true,
    strategyAlerts: 10,
    tokenAlerts: 50,
    tokenExplorerTimeframes: 'tenMinsAndAbove',
    whaleActivityAlerts: true,
  },
  // STAFF
  69: {
    ...defaultBenefits,
    advancedSecurityScore: true,
    coinAge: 'all',
    dailyBeans: 9999,
    marketLiveStreams: true,
    moneyLineTrendIndicator: true,
    onChainStrengthIndex: true,
    premiumEducation: true,
    privateDiscordCommunity: true,
    strategyAlerts: -1,
    tokenAlerts: -1,
    tokenExplorerTimeframes: 'tenMinsAndAbove',
    whaleActivityAlerts: true,
  },
  // ENTERPRISE
  90: {
    ...defaultBenefits,
    coinAge: 'all',
    api: true,
    dailyBeans: 2500,
    institutionalResearch: true,
    marketLiveStreams: true,
    moneyLineTrendIndicator: true,
    onChainStrengthIndex: true,
    premiumEducation: true,
    privateDiscordCommunity: true,
    sla: true,
    strategyAlerts: -1,
    tokenAlerts: -1,
    tokenExplorerTimeframes: 'tenMinsAndAbove',
    whaleActivityAlerts: true,
  },
};

type IBenefitsOrder = {
  [key in keyof IBenefitItems]: number;
};

export const benefitsOrder: IBenefitsOrder = {
  trendingTokens: 1,
  topTokensMarketCap: 2,
  realTimeTokens: 3,
  tokenExplorerTimeframes: 4,
  coinAge: 5,
  advancedSecurityScore: 6,
  tokenAlerts: 7,
  strategyAlerts: 8,
  forwardStrategyTesting: 9,
  fastSupport: 10,
  moneyLineTrendIndicator: 11,
  whaleActivityAlerts: 12,
  onChainStrengthIndex: 13,
  marketLiveStreams: 14,
  privateDiscordCommunity: 15,
  premiumEducation: 16,
  api: 17,
  sla: 18,
  institutionalResearch: 19,
  dailyBeans: 20,
};

import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import MoralisMoneyLogomark from '@/assets/img/MoralisMoneyLogomark.svg';
import MoralisMoneyLogotype from '@/assets/img/MoralisMoneyLogotype.svg';
import { ERoutePath } from '@/utils/ERoutePath';
import styles from './AsideMenuLogo.module.scss';

export const AsideMenuLogo = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  return (
    <Link
      href={ERoutePath.home}
      className={clsx(styles.logoLink, { [styles.collapsedLogo]: isCollapsed })}
      data-testid="test-nav-button-home"
    >
      <div className={styles.logoWrap}>
        <div>
          <Image priority alt="Moralis" height={26} src={MoralisMoneyLogomark.src} width={34} />
        </div>
        {!isCollapsed && (
          <div className={styles.logotype}>
            <Image priority alt="" height={20.25} src={MoralisMoneyLogotype.src} width={90} />
          </div>
        )}
      </div>
    </Link>
  );
};

import { isMagicAuth } from '@/components/accountSelector/utils/isMagicAuth';
import useUserStore from '@/stores/userStore';

export const useAuthMethodWithBalance = ({
  authMethod,
  balance,
}: {
  authMethod: string | undefined;
  balance: number;
}) => {
  const user = useUserStore((state) => state.user);

  const isMagicAuthentication = isMagicAuth(authMethod);
  const isMagicAutWithoutBalance = isMagicAuthentication && !balance;
  const getUserEmail = () => (isMagicAuthentication ? user?.email : undefined);

  return { isMagicAuthentication, isMagicAutWithoutBalance, getUserEmail };
};

import { Input } from 'moralis-ui';
import remCalc from '@/helpers/utils/remCalc';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HeaderTopBarSearchInput.module.scss';

export const HeaderTopBarSearchInput = ({ onClickOpenSearch }: { onClickOpenSearch: () => void }) => {
  return (
    <div className={styles.headerSearchInput} onClick={onClickOpenSearch}>
      <Input
        slots={{
          before: <FontAwesomeIcon icon={faSearch} style={{ height: remCalc('16') }} />,
        }}
        transparent
        placeholder="Search any token or wallet"
        size="sm"
      />
    </div>
  );
};

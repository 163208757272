import create from 'zustand';
import { FloatingElement, ReferenceType } from '@floating-ui/react';

type FloatingRefs = {
  setReference: (node: ReferenceType | null) => void;
  setFloating: (node: FloatingElement | null) => void;
};

type TModalStore = {
  isSearchModalOpen: boolean;
  closeSearchModal: () => void;
  openSearchModal: () => void;
  toggleSearchModal: () => void;
  refs: FloatingRefs | null;
  setRefs: (newRefs: FloatingRefs) => void;
};

export const useSearchStore = create<TModalStore>((set) => ({
  isSearchModalOpen: false,
  closeSearchModal: () => set({ isSearchModalOpen: false }),
  openSearchModal: () => set({ isSearchModalOpen: true }),
  toggleSearchModal: () => set((state) => ({ isSearchModalOpen: !state.isSearchModalOpen })),
  refs: null,
  setRefs: (newRefs) => set({ refs: newRefs }),
}));

import clsx from 'clsx';
import { Tabs } from 'moralis-ui';
import { EPortfolioList, portfolioListToTab } from '../manageWallets/libs/types';
import styles from './ListTabs.module.scss';

export const ListTabs = ({ tabId, onClick }: { tabId: EPortfolioList; onClick: (tabId: EPortfolioList) => void }) => {
  return (
    <Tabs
      className={clsx(styles.tabs, {
        [styles.firstSelected]: tabId === EPortfolioList.MY,
        [styles.watchedSelected]: tabId === EPortfolioList.WATCHED,
      })}
      direction="row"
      value={tabId}
      onChange={(tabId: string) => onClick(tabId as EPortfolioList)}
    >
      <Tabs.List>
        <Tabs.Tab id={EPortfolioList.MY}>{portfolioListToTab(EPortfolioList.MY)}</Tabs.Tab>
        <Tabs.Tab id={EPortfolioList.WATCHED}>{portfolioListToTab(EPortfolioList.WATCHED)}</Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

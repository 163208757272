/* eslint-disable sonarjs/prefer-single-boolean-return */
import {
  benefitsOrder,
  filteredBenefits,
  IBenefitItems,
  TCoinAgeTimeframes,
  TTokenExplorerTimeframes,
} from './benefits';

export type TPlanId = number;

export const FREE_PLAN_TYPE_ID = 1;
export const STARTER_PLAN_TYPE_ID = 20;
export const PRO_PLAN_TYPE_ID = 40;
export const STAFF_PLAN_TYPE_ID = 69;
export const ENTERPRISE_PLAN_TYPE_ID = 90;
export const FREE_PLAN_ID_TO_NAME = 'Free';
export const STARTER_PLAN_ID_TO_NAME = 'Starter';
export const PRO_PLAN_ID_TO_NAME = 'Pro';
export const STAFF_PLAN_ID_TO_NAME = 'Staff';
export const ENTERPRISE_PLAN_ID_TO_NAME = 'Enterprise';
export const FREE_PLAN_TAG_UNIQUE = 'free';
export const STARTER_PLAN_TAG_UNIQUE = 'starter';
export const PRO_PLAN_TAG_UNIQUE = 'pro';
export const STAFF_PLAN_TAG_UNIQUE = 'staff';
export const ENTERPRISE_PLAN_TAG_UNIQUE = 'enterprise';

export const filterBenefitsByByPlanId = (planId?: TPlanId): Partial<IBenefitItems> => {
  if (!planId && planId !== 0) return {};
  const planBenefits = filteredBenefits[planId];
  if (!planBenefits) return {};

  // Filter and map the values of the planBenefits object
  const filteredBenefitsMap = Object.entries(planBenefits).reduce(
    (acc: { [key: string]: boolean | number | TTokenExplorerTimeframes | TCoinAgeTimeframes }, [key, value]) => {
      const previousPlan: number | undefined = getPreviousPlanTypeId(planId);

      if (previousPlan !== undefined) {
        const previousBenefits = filteredBenefits[previousPlan];

        // Check for boolean type benefits and their state in the previous plan
        if (value === true && previousBenefits[key as keyof IBenefitItems] === false) {
          acc[key] = value;
        }
      } else {
        // For the lowest plan, add if the benefit is true
        if (value === true) {
          acc[key] = value;
        }
      }

      // Handle numeric benefits
      if (typeof value === 'number') {
        acc[key] = value;
      }

      // Specifically handle the TTokenExplorerTimeframes type
      if (
        typeof value === 'string' &&
        ['oneWeekAndAbove', 'oneDayAndAbove', 'tenMinsAndAbove', 'sevenDaysAndAbove', 'all'].includes(value)
      ) {
        acc[key] = value;
      }

      return acc;
    },
    {},
  );

  // Order the filtered benefits according to the defined order
  const orderedFilteredBenefits: {
    [key: string]: boolean | number | TTokenExplorerTimeframes | TCoinAgeTimeframes;
  } = {};
  Object.keys(benefitsOrder).forEach((key) => {
    if (key in filteredBenefitsMap) {
      orderedFilteredBenefits[key] = filteredBenefitsMap[key];
    }
  });

  return orderedFilteredBenefits;
};

export const getPlanStageLevelFromTag = (currentPlanTag?: string): number => {
  const tag = currentPlanTag?.toLocaleLowerCase();
  switch (true) {
    case tag?.includes(FREE_PLAN_TAG_UNIQUE):
      return 1;
    case tag?.includes(STARTER_PLAN_TAG_UNIQUE):
      return 20;
    case tag?.includes(PRO_PLAN_TAG_UNIQUE):
      return 40;
    case tag?.includes(STAFF_PLAN_TAG_UNIQUE):
      return 69;
    case tag?.includes(ENTERPRISE_PLAN_TAG_UNIQUE):
      return 90;
    default:
      return 0;
  }
};

export const isValidPlanId = (planId?: unknown): planId is TPlanId => {
  return (
    planId === FREE_PLAN_TYPE_ID ||
    planId === STARTER_PLAN_TYPE_ID ||
    planId === PRO_PLAN_TYPE_ID ||
    planId === ENTERPRISE_PLAN_TYPE_ID
  );
};

export const getNextPlanTypeId = (currentPlanTypeId: TPlanId): TPlanId | undefined => {
  if (currentPlanTypeId === FREE_PLAN_TYPE_ID) return STARTER_PLAN_TYPE_ID;
  if (currentPlanTypeId === STARTER_PLAN_TYPE_ID) return PRO_PLAN_TYPE_ID;
  if (currentPlanTypeId === PRO_PLAN_TYPE_ID) return ENTERPRISE_PLAN_TYPE_ID;
  if (currentPlanTypeId === ENTERPRISE_PLAN_TYPE_ID) return undefined;
};

export const getPreviousPlanTypeId = (currentPlanTypeId: TPlanId): TPlanId | undefined => {
  if (currentPlanTypeId === FREE_PLAN_TYPE_ID) return undefined;
  if (currentPlanTypeId === STARTER_PLAN_TYPE_ID) return FREE_PLAN_TYPE_ID;
  if (currentPlanTypeId === PRO_PLAN_TYPE_ID) return STARTER_PLAN_TYPE_ID;
  if (currentPlanTypeId === ENTERPRISE_PLAN_TYPE_ID) return PRO_PLAN_TYPE_ID;
};

export const getCurrentPlanNameFromPlanId = (planId: number): string | undefined => {
  if (planId === FREE_PLAN_TYPE_ID) return FREE_PLAN_ID_TO_NAME;
  if (planId === STARTER_PLAN_TYPE_ID) return STARTER_PLAN_ID_TO_NAME;
  if (planId === PRO_PLAN_TYPE_ID) return PRO_PLAN_ID_TO_NAME;
  if (planId === ENTERPRISE_PLAN_TYPE_ID) return ENTERPRISE_PLAN_ID_TO_NAME;
};

export const getNextPlanIdNameFromPlanId = (planId: TPlanId): string | undefined => {
  if (planId === FREE_PLAN_TYPE_ID) return STARTER_PLAN_ID_TO_NAME;
  if (planId === STARTER_PLAN_TYPE_ID) return PRO_PLAN_ID_TO_NAME;
  if (planId === PRO_PLAN_TYPE_ID) return ENTERPRISE_PLAN_ID_TO_NAME;
  if (planId === ENTERPRISE_PLAN_TYPE_ID) return undefined;
};

export const getPreviousPlanIdNameFromPlanId = (planId: TPlanId): string | undefined => {
  if (planId === FREE_PLAN_TYPE_ID) return undefined;
  if (planId === STARTER_PLAN_TYPE_ID) return FREE_PLAN_ID_TO_NAME;
  if (planId === PRO_PLAN_TYPE_ID) return STARTER_PLAN_ID_TO_NAME;
  if (planId === ENTERPRISE_PLAN_TYPE_ID) return PRO_PLAN_ID_TO_NAME;
};

export const isPlanTypeIdHigherThan = (planTypeId: TPlanId, planTypeIdToCompare: TPlanId): boolean => {
  if (planTypeId === FREE_PLAN_TYPE_ID) return false;
  if (planTypeId === STARTER_PLAN_TYPE_ID && planTypeIdToCompare === FREE_PLAN_TYPE_ID) return true;
  if (planTypeId === PRO_PLAN_TYPE_ID && planTypeIdToCompare === STARTER_PLAN_TYPE_ID) return true;
  if (planTypeId === ENTERPRISE_PLAN_TYPE_ID && planTypeIdToCompare === PRO_PLAN_TYPE_ID) return true;
  return false;
};

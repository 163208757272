import { useRef, useState } from 'react';
import clsx from 'clsx';
import { Card } from 'moralis-ui';
import { useFeatureFlag } from '@/helpers/hooks/useFeatureFlag';
import useOnClickOutside from '@/helpers/hooks/useOnClickOutside';
import { canAddNewWallet } from '../manageWallets/utils/canAddNewWallet';
import { useAccountSelector } from './hooks/useAccountSelector';
import { AccountSelectorSkeleton } from './AccountSelectorSkeleton';
import { Buttons } from './Buttons';
import CurrentlySelected from './CurrentlySelected';
import { ListTabs } from './ListTabs';
import { WalletList } from './WalletList';
import styles from './AccountSelector.module.scss';

export const AccountSelector = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const { data, isLoading, tabId, setTabId, list, mergedLists } = useAccountSelector();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const hasNewSidebar = useFeatureFlag('sidebar');

  const containerRef = useRef(null);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useOnClickOutside(containerRef, closeDropdown);

  if (!data) {
    return <AccountSelectorSkeleton isCollapsed={hasNewSidebar && isCollapsed} />;
  }
  return (
    <div ref={containerRef}>
      <Card className={clsx(styles.card, { [styles.collapsed]: isCollapsed })} size="sm" transparent>
        <CurrentlySelected
          isCollapsed={isCollapsed}
          lists={data}
          tabId={tabId}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
        <div
          className={clsx(styles.trade_dropdown, styles.dropdown, {
            [styles.visible]: isDropdownOpen,
            [styles.newSidebar]: hasNewSidebar,
            [styles.collapsed]: isCollapsed,
          })}
        >
          <ListTabs onClick={setTabId} tabId={tabId} />
          {list && (
            <>
              <WalletList list={list} tabId={tabId} />
              <Buttons canAddNewWallet={canAddNewWallet(mergedLists)} isLoading={isLoading} />
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

import { UserWallet } from '@/components/manageWallets/libs/types';
import { isNullish } from '../isNullish';
import { logApp } from '../logApp';

const log = logApp.create('extractMultiWalletBalance');

export const extractMultiWalletBalance = (list: UserWallet[]) => {
  if (!list?.length) {
    log.warnError('[extractMultiWalletBalance]', 'Invalid input', {
      list,
    });
    return 0;
  }

  const balance = list.reduce((acc, wallet) => acc + (Number(wallet.balanceUsd) || 0), 0);

  if (isNullish(balance)) {
    log.warnError('[extractMultiWalletBalance]', 'Could not extract multi wallet balance', {
      list,
    });
    return 0;
  }

  return balance;
};

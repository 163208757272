import { useState } from 'react';
import clsx from 'clsx';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import BeanCounter from '@/components/common/BeanCounter';
import useAnalyticsStore from '@/stores/analyticsStore';
import useAuthStore from '@/stores/authStore';
import useBeanStore from '@/stores/beanStore';
import useUserStore from '@/stores/userStore';
import { filteredBenefits } from '@/utils/benefits';
import { getPlanStageLevelFromTag } from '@/utils/planTypeId';
import { faSpinnerThird, faStars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
import { useCountdown } from './hooks';
import styles from './BeanCollect.module.scss';

interface BeanCollectProps {
  handleClaimBeans: () => Promise<void>;
  isAllowedBeans?: boolean;
}

export const BeanCollectUI = ({ handleClaimBeans }: BeanCollectProps) => {
  const auth = useAuthStore((state) => {
    return state.authToken;
  });
  const beans = useBeanStore((state) => {
    return state.beans;
  });
  const { stage, user } = useUserStore((state) => {
    return state;
  });
  const [isFetchingBeans, setIsFetchingBeans] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const tracker = useAnalyticsStore((state) => {
    return state.tracker;
  });

  const nextCollectionDate = beans?.nextDate;

  const nextDate = fromUnixTime(nextCollectionDate || Math.floor(Date.now() / 1000));
  const lastDate = fromUnixTime(nextDate.getTime() / 1000 - 86400);

  const remainingTimeInSeconds = useCountdown(nextDate);
  const isEnabled = remainingTimeInSeconds <= 0;

  const totalDurationInSeconds = differenceInSeconds(nextDate, lastDate);
  const timeElapsedInSeconds = totalDurationInSeconds - remainingTimeInSeconds;

  const timeLeftPercent = (elapsed: number, totalDuration: number) => {
    return Number(((elapsed / totalDuration) * 100).toFixed(0));
  };

  const { rive, RiveComponent } = useRive({
    src: '/BeansConfetti.riv',
    autoplay: false,
    onPlay: () => {
      setIsPlaying(true);
    },
    onStop: () => {
      setIsPlaying(false);
      rive?.cleanup();
    },
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.BottomCenter,
    }),
  });

  const clickHandler = async (isEnabled: boolean) => {
    if (!isEnabled) return;
    setIsFetchingBeans(true);
    const isClaimSuccessful = auth && (await handleClaimBeans());
    setIsFetchingBeans(false);
    if (isClaimSuccessful) {
      tracker?.trackButtonClick('Claim Daily Beans');
      rive?.play('Timeline 1');
    }
  };

  const beansToClaim =
    stage?.beanRewards || filteredBenefits[getPlanStageLevelFromTag(user?.plan?.tag)]?.dailyBeans || 500;

  return (
    <>
      <div className={styles.beanCollect}>
        {isEnabled ? (
          <div
            className={clsx(styles.collectBeans, { [styles.claimGlow]: isEnabled })}
            onClick={() => {
              clickHandler(isEnabled);
            }}
          >
            <FontAwesomeIcon
              spin={isFetchingBeans}
              icon={isFetchingBeans ? faSpinnerThird : faStars}
              width={14}
              height={14}
            />
            Claim {beansToClaim} Beans
            <div
              className={styles.buttonProgress}
              style={{
                width: isEnabled ? '100%' : `${timeLeftPercent(timeElapsedInSeconds, totalDurationInSeconds)}%`,
              }}
            />
          </div>
        ) : (
          <div className={styles.beanCounter}>
            <BeanCounter />
          </div>
        )}
      </div>
      <div className={clsx(styles.rive, { [styles.isPlaying]: isPlaying })}>
        <RiveComponent />
      </div>
    </>
  );
};

import { log } from './log';

export const parseFeatFlagDictSafe = (str = '{}'): Record<string, unknown> => {
  try {
    return JSON.parse(str);
  } catch (err) {
    log.warnError('Failed to parse feature flag cookie', err);
    return {};
  }
};

import Image from 'next/image';
import * as blockies from 'blockies-ts';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface IBlockiesAvatarProps {
  address: string;
  className?: string;
  logoIcon?: string | null;
}
const BlockiesAvatar = ({ address = '0x0000000', className, logoIcon }: IBlockiesAvatarProps) => {
  const canvas = blockies.create({ seed: address.toLowerCase() });
  const imgSrc = canvas.toDataURL();

  return (
    <Image
      alt="Blockie for address"
      className={clsx(styles.imgBlockies, className)}
      height={20}
      src={logoIcon || imgSrc}
      width={20}
    />
  );
};

export default BlockiesAvatar;

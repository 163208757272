import { useRouter } from 'next/router';
import clsx from 'clsx';
import { filterMagicAddressWithNoBalance } from '@/utils/filterMagicAddressWithNoBalance';
import { formatCurrencyWithoutRounding } from '@/utils/fmt';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlockiesAvatar } from '../common/BlockiesAvatar';
import { UserWallet } from '../manageWallets/libs/types';
import { aggregateAddresses } from '../manageWallets/utils/list';
import { portfolioAggregateURL } from '../portfolio/lib/portfolioURL';
import { listNameConverter } from '../portfolio/utils/getStaticListName';
import { isSelectingOption } from './utils/isSelectingOption';
import { WalletOptions } from './WalletOptions';
import styles from './Wallet.module.scss';

export const Aggregate = ({
  wallets,
  onClick,
  isSelected,
}: {
  isSelected: boolean;
  onClick: () => void;
  wallets: Array<UserWallet>;
}) => {
  const { push } = useRouter();

  const resolvedAggregateUrl = listNameConverter.from(wallets[0].apiData.settings?.currentListName);
  const aggregatedAddresses = aggregateAddresses(filterMagicAddressWithNoBalance(wallets) || []);
  const aggregatedNetWorth = wallets.map((wallet) => wallet.balanceUsd).reduce((acc, curr) => acc + curr, 0);

  return (
    <div
      className={clsx(styles.container, {
        [styles.selected]: isSelected,
      })}
      role="button"
      onClick={(e) => {
        // Check if the click was on the ellipsis icon, if so, do nothing
        if (isSelectingOption(e)) return;
        onClick();
        push(portfolioAggregateURL.tokens(resolvedAggregateUrl));
      }}
    >
      <BlockiesAvatar address={aggregatedAddresses} className={clsx(styles.blockie, styles.noUserSelect)} />

      <span>
        <p className={clsx('body-14-regular', 'color-dropdown-active', styles.noUserSelect)}>All Wallets</p>
        <p className={clsx('caption-10-regular', 'color-input-active', styles.walletDetails, styles.noUserSelect)}>
          {formatCurrencyWithoutRounding(aggregatedNetWorth, undefined, undefined, 2)}
        </p>
      </span>

      <div className={clsx('color-headline-secondary', styles.icons)}>
        {isSelected && <FontAwesomeIcon height={11} icon={faCheck} width={11} />}
        <WalletOptions
          isLoggedInAccount={false}
          onViewPortfolio={() => push(portfolioAggregateURL.tokens(resolvedAggregateUrl))}
        />
      </div>
    </div>
  );
};

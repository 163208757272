const remCalc = (px: string, base = 16): string => {
  if (typeof px === 'string' && px.includes('%')) {
    return px;
  }

  const tempPx: number = parseInt(px.replace('px', ''), 10);
  return `${(1 / base) * tempPx}rem`;
};

export default remCalc;
